
.svg-container{
    position: fixed;
    width:100vw;
    height: 100vh;
    /* float:left; */
    /* top: 2.7rem; */
  }
  
  #color-fill {
    font-family: "PT Sans", "Arial", sans-serif;
    background-color: white;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    position:relative;
    left: 0px;
    font-size: 100%;
    vertical-align: baseline;
  }
  
  svg .planet {
    stroke: #4F518C;
    fill:#907AD6;
    fill-opacity: 0.3;
    stroke-width: 0.2;
  }
  
  svg .sun {
    stroke: #4F518C;
    fill:#907AD6;
    fill-opacity: 0.3;
    stroke-width: 0.2;
  }
  
  svg .planet:hover,
  svg .planet:active  {
    stroke: #2C2A4A;
    fill-opacity: 0.4;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
  }
  
   svg .small { 
      font: 0.4rem  monospace; 
      fill: #2C2A4A;
      -webkit-tap-highlight-color: rgba(255,255,255,0);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      outline: none;
    }
  
    svg .medium { 
      font: 1rem  monospace; 
      fill: #2C2A4A;
      -webkit-tap-highlight-color: rgba(255,255,255,0);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      outline: none;
    }
    
    .hex {
      fill-opacity: 1;
      fill: white;
      stroke: #4F518C;
      stroke-width: 0.2;
    }
  
    .temp {
      color: #DABFFF;
      color: #907AD6;
      color: #4F518C;
      color: #2C2A4A;
      color: #7FDEFF;
  }
  
  
    .infobox {
        position: fixed;
        bottom: 0.8rem;
        left: -20rem;
        width: 13rem;
        height: 7.3rem;
        border: 1px solid #DABFFF;
        border-radius: 5px;
        padding: 0.5rem;
        background-color: rgba(218, 191, 255, 0.274);
        transition: left;
        -webkit-transition: left;
        transition-duration: 0.6s;
        -webkit-transition-duration: 0.6s;
        z-index: 1;
        box-sizing: border-box;
    }
  
    .systemName {
      font-weight:600;
      color: #2C2A4A;
    }
  
    .systemCoords {
      font-size: 0.5rem;
      margin-left:0.3rem;
    }
  
    .description {
      font-size:0.62rem;
      text-align: left;
    }
  
    .systemHeader {
      display:flex;
      align-items:baseline;
      justify-items: baseline;
    }
  
    .menubox{
      position: absolute;
      bottom: 0.3rem;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: calc(100% - 1rem);
    }
    .menubox button {
      border: 1px solid #907AD6;
      background-color: #DABFFF;
      color: #2C2A4A;
      border-radius: 5px;
      height: 2rem;
      width: 5.3rem;
      -webkit-tap-highlight-color: rgba(255,255,255,0);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      outline: none;
    }
  
    .system {
      cursor: pointer;
      fill: rgb(234, 221, 253);
      fill-opacity: 1;
      stroke: #4F518C;
      stroke-width: 0.2;
    }
    
    
    #color-fill .system:hover,
    #color-fill .system:active {
      fill: #DABFFF;
    }
  
  
    .system:hover,
    .system:active {
      fill-opacity: 1;
      /* stroke: #64a2ff; */
      /* stroke-width: 2.2; */
      z-index:1000000;
      /* transform: scale(0.9);
      -webkit-transform: scale(0.9); */
      /* transform-origin: 50% 50%;
      -webkit-transform-origin: 50% 50%; */
      -webkit-tap-highlight-color: rgba(255,255,255,0);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      outline: none;
    }
    
    .system {
      transition: transform, fill-opacity, stroke-width;
      -webkit-transition: transform, fill-opacity, stroke-width;
      transition-duration: 1s;
      -webkit-transition-duration: 1s;
    }

    .systeminfo {
      display: block;
      position: fixed;
      top:15vh;
      left:15vw;
      width: 70vw;
      height: 70vh;
      color: white;
      background-color: transparent;
    }

    .systeminfo_name {
      font-family: 'Courier New', Courier, monospace;
      font-size: 1.5rem;
      font-weight: 700;
    }

    .systeminfo_desc {
      font-weight: 100;
    }

    .systeminfo_planet {
      margin-top:1em;
      margin-bottom:1em;
    }

    .systeminfo_planet_name {  
      font-family: 'Courier New', Courier, monospace;
      font-size: 1.2rem;
      font-weight: 700;
    }

    .systeminfo_planet_desc {  
      font-size: 0.9rem;
    }

    .overlay_systeminfo {
      display: block;
      position: fixed;
      color: white;
      top:0;
      left:0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.808);
    }
  
    /* @media (min-width: 800px) {
      .svg-container {
        width:calc(100vw - 300px);
        height: 100vh;
        float:left;
        top: auto;
      }
      .infobox {
        bottom:0.8rem;
        margin-left: 300px;
      }
    } */