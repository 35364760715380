.shereshoy {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #453A49;
    background-color: rgba(234, 221, 253,0);;
    /* border-bottom: solid 3px #dd217f; */
    user-select: none; 
    width: 100vw;
    height: 2.5rem;
    position: fixed;  
    box-sizing: border-box;
    z-index: 1000;
}

.palette {
    color: #191D32;
    color: #282F44;
    color: #453A49;
    color: #6D3B47;
    color: #BA2C73;
}

.palette {
    color: #D8DDEF;
    color: #A0A4B8;
    color: #7293A0;
    color: #45B69C;
    color: #21D19F;
}

.palette {
    color: #DABFFF;
    color: #907AD6;
    color: #4F518C;
    color: #2C2A4A;
    color: #7FDEFF;
}

.labeltext {
    margin-left: .5rem;
}

.userButton {
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    width: fit-content;
    padding: 4px;
    padding-left: 6px;
    line-height: 0.7rem;
    font-size: 0.7rem;
    margin-right: .5rem;
}

.userButton:hover {
    background-color: #ffffff30;

}

.loginButton {
    position: relative;
    display: block;
    background-color: #5865F2;
    border-radius: 5px;
    width: fit-content;
    padding: 4px;
    padding-left: 6px;
    line-height: 0.7rem;
    margin-right: .5rem;

}

.buttonText, .buttonText:visited, .buttonText:active, .buttonText:hover  {
    margin: 5px;
    color: white;
    font-size: 0.7rem;
}